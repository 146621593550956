"use client";

import {
  Loader2,
  MessageSquare,
  MessageSquareText,
  Mic2,
  Popcorn,
  Wand,
} from "lucide-react";
import { unstable_noStore as noStore } from "next/cache";
import { Skeleton } from "~/components/ui/skeleton";
import { api } from "~/trpc/react";
import { redirect } from "next/navigation";

export default function Home() {
  redirect("/scenarios");

  noStore();
  const getUserStats = api.user.getStats.useQuery();

  const arrayOfStats = [
    {
      title: "Chats",
      value: getUserStats.data?.userChatCount,
      icon: MessageSquare,
    },
    {
      title: "Messages",
      value: getUserStats.data?.userMessageCount,
      icon: MessageSquareText,
    },
    {
      title: "Enhancements",
      value: getUserStats.data?.enhancementCount,
      icon: Wand,
    },
    {
      title: "Audio Messages",
      value: getUserStats.data?.userAudioMessageCount,
      icon: Mic2,
    },
    {
      title: "Created Scenarios",
      value: getUserStats.data?.userScenarioCount,
      icon: Popcorn,
    },
  ];

  return (
    <main>
      <div className="flex flex-col gap-5">
        <div>
          <div className="flex flex-row items-center gap-1">
            <h2 className="text-2xl font-bold tracking-tight">Your progress</h2>
            {getUserStats.isLoading && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
          </div>
          <p className="text-muted-foreground">
            See your progress and achievements here
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {arrayOfStats.map((stat, i) => (
            <div
              key={i}
              className="row-span-1 rounded-xl border-2 border-slate-200 p-4 dark:bg-neutral-900"
            >
              <h3 className="mb-2 flex items-center gap-2 text-lg font-semibold">
                <stat.icon className="h-6 w-6" />
                {stat.title}
              </h3>
              {getUserStats.isLoading ? (
                <Skeleton className="h-8 w-8" />
              ) : (
                <p className="text-2xl font-bold">{stat.value}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}
